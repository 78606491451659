<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
        <vx-card title="Edit Setting">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <span>Name</span>
                    <vs-input class="w-full" id="name" name="name" v-model="name" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <span>Description</span>
                    <vs-input class="w-full" id="description" name="description" v-model="description" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <span>Value {{ set_type }}</span>
                    <multiselect v-if="set_type=='option'"
                        class="selectExample"
                        id="value"
                        v-model="value"
                        placeholder="Select value"
                        :options="optionsValue"
                        :multiple="false"
                        :searchable="false"
                        label="label"
                    />
                    <vs-input v-else-if="set_type=='integer'"
                        class="w-full"
                        type="number"
                        v-model="value"
                        name="setting_int"
                    />
                    <vs-input v-else-if="set_type=='string'"
                        class="w-full"
                        v-model="value"
                        name="setting_int"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                <div class="vx-col w-full">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import DataTable from "./dataTable.vue";

export default {
    components: {
        DataTable,
    },
    props: {
        id: {
            type: Number
        },
    },
    data() {
        return {
            title: "Setting OMS",
            baseUrl: "/api/v1/setting/setting-oms/",
            name:           '',
            description:    '',
            value:          '',
            // optionsValue:   ["Prorate", "Priority/FIFS/Prorate", "FIFS/Priority/Prorate"]
            set_type:       "string",
            optionsValue:   []
        };
    },
    mounted() {
        if (this.id) {
            this.fetchData();
        }
    },
    methods: {
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        handleSubmit() {
            this.$vs.loading();
            this.updateData();
        },
        fetchData() {
            this.$vs.loading();
            this.$http.get(this.baseUrl + this.id)
            .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.name = resp.data.Name;
                    this.description = resp.data.Description;
                    this.optionsValue = resp.data.SettingOptions;
                    this.set_type     = resp.data.Type
                    this.value        = resp.data.Value;
                    if (!this.value) {
                        this.value  = resp.data.DefaultValue;
                    }
                    this.$vs.loading.close();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }

                this.optionsValue.forEach(val => {
                    if (val.value == resp.data.Value) {
                        this.value = val
                    }
                })
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        paramData() {
            let val = this.value
            if (this.value.value) {
                val = this.value.value
            }
            return {
                Name:           this.name,
                Description:    this.description,
                Value:         val,
            };
        },
        updateData() {
            this.$http.put(this.baseUrl + this.id, this.paramData())
            .then(response => {
                this.$vs.loading.close();
                if (response.code == 200) {
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: this.title + " Updated",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: response.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        }
    },
    watch: {
        Id(v) {
            if (v) {
                this.fetchData();
            } else {
                // ...
            }
        }
    },
};
</script>